import { v4 as uuidv4 } from "uuid";

export const sendConversionData = async (conversionData) => {
  const baseBody = {
    source: "pedderspl",
  };

  const fbc = getCookie("_fbc");
  const fbp = getCookie("_fbp");

  try {
    const response = await fetch("https://api.ozparts.eu/CAPI", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        _fbc: fbc || "",
        _fbp: fbp || "",
      },
      body: JSON.stringify({ ...baseBody, ...conversionData }),
    });

    const data = await response.json();
    if (data.status === "success") {
      console.log("Conversion sent successfully");
    } else {
      console.error("Error sending conversion:", data.error);
    }
  } catch (error) {
    console.error("Request failed", error);
  }
};

function getCookie(name) {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  return match ? match[2] : null;
}

export const addToCartCAPI = async (part, quantity, country) => {
  const event_name = "AddToCart";
  const event_id = uuidv4();
  const event_time = Math.floor(Date.now() / 1000);

  const defaultStock = part.available.length
    ? part.available.find((e) => e.default)
    : "";

  const url = window.location.href;

  const price =
    part.price.grossprice ||
    part.price.prices[defaultStock.location].grossprice;

  const original_event_data = {
    event_name: event_name,
    event_time: event_time,
  };

  const custom_data = {
    currency: part.price.currency,
    content_type: "product",
    value: quantity * price,
    content_name: part.description,
    content_ids: [part.name],
    num_items: quantity,
    contents: [
      {
        id: part.name,
        quantity: quantity,
        item_price: price,
      },
    ],
  };

  const user_data = {
    country: country,
  };

  const conversionData = {
    event_name: event_name,
    event_id: event_id,
    event_time: event_time,
    action_source: "website",
    event_source_url: url,
    user_data,
    custom_data,
    original_event_data,
  };

  window.fbq("track", "AddToCart", custom_data, {
    eventID: event_id,
  });

  await sendConversionData(conversionData);
};

export const pageViewCAPI = async (country) => {
  const event_name = "PageView";
  const event_id = uuidv4();
  const event_time = Math.floor(Date.now() / 1000);
  const url = window.location.href;

  const custom_data = {
    currency: "PLN",
  };
  const original_event_data = {
    event_name: event_name,
    event_time: event_time,
  };
  const user_data = {
    country: country,
  };

  const conversionData = {
    event_name: event_name,
    event_id: event_id,
    event_time: event_time,
    action_source: "website",
    event_source_url: url,
    user_data,
    custom_data,
    original_event_data,
  };

  if (typeof window.fbq === "function") {
    window.fbq("track", "PageView", custom_data, {
      eventID: event_id,
    });
  }

  await sendConversionData(conversionData);
};

export const purchaseCAPI = async (data) => {
  const event_name = "Purchase";
  const event_id = uuidv4();
  const event_time = Math.floor(Date.now() / 1000);
  const url = window.location.href;

  const custom_data = {
    currency: data.currency.iso,
    value: data.grossamount,
    num_items: data.quantity,
    contents: data.transactionlines.map((line) => ({
      id: line.item.name,
      quantity: line.quantity,
      item_price: line.grosamount,
    })),
  };

  const user_data = {
    em: data.shipemail,
    ph: data.shipphone,
    country: data.billcountry,
    ct: data.billcity,
    fn: data.billname.split(" ")[0],
    ln: data.billname.split(" ")[0],
    zp: data.billzip,
  };

  const original_event_data = {
    event_name: event_name,
    event_time: event_time,
  };

  const conversionData = {
    event_name: event_name,
    event_id: event_id,
    event_time: event_time,
    action_source: "website",
    event_source_url: url,
    user_data,
    custom_data,
    original_event_data,
  };

  window.fbq("track", "Purchase", custom_data, {
    eventID: event_id,
  });

  await sendConversionData(conversionData);
};

export const viewContentCAPI = async (part, country) => {
  const event_name = "ViewContent";
  const event_id = uuidv4();
  const event_time = Math.floor(Date.now() / 1000);
  const url = window.location.href;

  const defaultStock = part.available.length
    ? part.available.find((e) => e.default)
    : "";

  const price =
    part.price.grossprice ||
    part.price.prices[defaultStock.location].grossprice;

  const original_event_data = {
    event_name: event_name,
    event_time: event_time,
  };
  const user_data = {
    country: country,
  };
  const custom_data = {
    content_name: part.name,
    content_ids: [part.name],
    content_type: "product",
    currency: part.price.currency,
    value: price,
  };

  const conversionData = {
    event_name: event_name,
    event_id: event_id,
    event_time: event_time,
    action_source: "website",
    event_source_url: url,
    user_data,
    custom_data,
    original_event_data,
  };

  if (typeof window.fbq === "function") {
    window.fbq("track", "ViewContent", custom_data, {
      eventID: event_id,
    });
  }

  await sendConversionData(conversionData);
};

export const initiateCheckoutCAPI = async (data) => {
  const event_name = "InitiateCheckout";
  const event_id = uuidv4();
  const event_time = Math.floor(Date.now() / 1000);

  const url = window.location.href;

  const custom_data = {
    currency: data.currency.name,
    value: data.grossamount,
    num_items: data.quantity,
    contents: data.transactionlines.map((item) => ({
      id: item.item.name,
      quantity: item.quantity,
      item_price: item.price,
    })),
  };

  const user_data = {
    country: data.billcountry,
  };

  const original_event_data = {
    event_name: event_name,
    event_time: event_time,
  };

  const conversionData = {
    event_name: event_name,
    event_id: event_id,
    event_time: event_time,
    action_source: "website",
    event_source_url: url,
    user_data,
    custom_data,
    original_event_data,
  };

  if (typeof window.fbq === "function") {
    window.fbq("track", "InitiateCheckout", custom_data, {
      eventID: event_id,
    });
  }

  await sendConversionData(conversionData);
};
